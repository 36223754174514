import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BlockUiService} from '../shared/service/block.ui.service';
import {SecurityContext} from './security-context';
import {ApplicationRepository} from '../mt-repository/application-repository';
import {RepositoryKey} from '../mt-repository/repository-key';
import {RepositoryType} from '../mt-repository/repository';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private applicationRepository: ApplicationRepository,
                private blockUiService: BlockUiService,
                private securityContext: SecurityContext) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (this.router.url !== '/login') {
                        this.applicationRepository.setItem(RepositoryKey.LAST_SEEN_PAGE, this.router.url, RepositoryType.LOCAL)
                    }
                    this.securityContext.clear();
                    this.router.navigateByUrl('/login');
                }
                if (error.status === 403) {
                    this.router.navigateByUrl('/access-denied');
                    this.blockUiService.stopBlock();
                }
                return throwError(error);
            }));
    }
}
