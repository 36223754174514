import {MenuItemPermission} from './menu-item-permission';

export class AuthUserInfo {
    // tslint:disable-next-line:variable-name
    access_token?: string;
    // tslint:disable-next-line:variable-name
    token_type?: string;
    // tslint:disable-next-line:variable-name
    first_login?: boolean;
    // tslint:disable-next-line:variable-name
    is_affiliate?: boolean;
    can_spoof?: boolean;
    permissions?: MenuItemPermission[];
    qr?: boolean;
}

export class TwoFAData {
    qr: boolean;
}
